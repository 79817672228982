// @font-face {
//     font-family: Albert;
//     src: url(/assets/fonts/albertshal.otf);
// }



$navyblue: #000; /* #0F1626; */
$leather:  #767b7e;  /* #AB987A; */
$corall: #FF533D;
$egg: #F5F5F5;

$dark_bg: #101314;
$darker_bg: #2e3439;
$darker_bg: #000;


$light_text: #d3d2ce;


// General stuff

body {
    font-size: 20px;
    font-weight: normal;
    line-height: 32px;
    color: $light_text;
    background: $darker_bg;

}

ul { margin-left: 20px;}

li { list-style-type: disc; }

p {
    font-size: 20px;
    font-weight: normal;
    line-height: 32px;
    color: $light_text;
    margin-bottom: 25px;
    a {
        color: $corall;
    }
}

li {

    font-size: 20px;
}

.widget {

    background: transparent;
    color: #000;
    p, h1 {
    color: #000;

    }

}



.grey-bg {
	background: $dark_bg;
}

.white-bg {
    background: #fff;
    p,h1,h2,h3,h4 { color: #000;}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	 
    color: $light_text;
	 
}

// h1 {
//     font-family: Albert;
//     font-weight: 100;
// }

h3 {
    margin-top: 35px;
    margin-bottom: 10px;
}

// Top of page
.menu-area {
    background: $leather;
    background-image: url(/assets/img/black-paper.png);

    
}

.pageheader {
    background-position-x: center;
    background-position-y: center;
    background-repeat-x: no-repeat;
}

.transparent-menu ul li:hover a i {
    color: #FFF;
}

.meanmenu-reveal { margin-top: -32px !important;}
.mean-container .mean-nav ul li { list-style: none;}
#mobile-menu {
    ul {
        li {
            
            a {
                color: #FFF;
                font-size: 19px;

                &:hover {
                    color: #CCC;
                }

            }
            
            .sub-menu {
                li {
                    a {
                        color: #000;
                    }
                }
            }
        }
    }
}

.header-top {
    a {
        color: #fff;
    }
}
// Slider

.slider-content {

    h1 {
        line-height: 90px;
    }
}


// Room

.features-wrap {
    height: 320px;

    .features-icon {
        height: 58px;
    }

    h4 {
        color: $light_text;
    }

    p {
        font-size: 16px;
        font-weight: normal;
        line-height: 28px;
        color: $light_text;
        margin-bottom: 0px;
    }

    .read_wrap {
        position: absolute;
        bottom: 6px;
        left: 35%;
        .service_read {
            display: block;
            font-size: 20px;
        }
    }    

}

.arena_info {
    margin-left: 12px;
}

// About

@media screen and (min-width: 991px) {
    .about-img {
        img {
            border-radius: 50%;
            max-width: 300px;
        }
       
    }
}
.about-info-text {
    h2 {
        text-transform: none;
    }
}

// Section booking

section.booking button {
    color: #000;
}

section.booking  {
    .participants {
        label {
            color: #000;
            font-size: 18px;
        }
    }
    select {
        border: 1px solid #999 !important;
    }
}

section.booking .participants button {
 
    margin-top: 40px !important;
}


.apuz-simple-booking {

    p, h1 {
        color: #101314;
    }
}


// Teamphotos

.teamphotos {
    .features-wrap {
        border: none;
        padding: 9px;
        height: auto;


        img {
            width: 100%;
            max-width: 240px;
        }
    }
    h4 {
        margin-top: 30px;
    }
    .fblike {


        bottom: 20px;
    }
    
}

// Footer
.footer-section {
    background: $navyblue;
    color: #fff;
    .footer-widget {
        color: #fff;

    }
    p {
        color: #fff;
    }
    a {
        color: #ff533d;
    }
    .subscribe-form input {
        width: 100%;
        padding: 14px 28px;
        background: $leather;
        border: none;
        color: #fff;
    }

    .footer-logo-bg {

        background: url(/assets/img/logo/footer_logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: right;
    }


}

// Stripe
.paymentbox {
    label {
        color: #000;
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    .trip-footer {
        display: block;
        margin: auto;
    }
    .hide-mobile {
        display: none;
    }
}

@media screen and (min-width: 768px) {
 
    .hide-desktop {
        display: none;
    }
    .hide-mobile {
        display: initial;
    }
}

.single-counter {
     p {
	color: #ddd;
	font-size: 16px;
	margin: 0;
	text-transform: none;
    font-weight: 500;
    
    a {
        color: $corall;
    }
}
}

.c {
    color: #fff;
    display: block;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 7px;
}

/* Clients */

.single-clients {

    width: 120px;
    vertical-align: middle

}



/* Knappar */


.button-book {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;

    

    background-color: #FF533D; // #97cc76;
//    background-image: linear-gradient(to bottom, #97cc76, #8bcc62);
    border: 1px solid rgb(255, 61, 36);
  //  box-shadow: inset 0 1px 0 #c6e6b3, inset 0 -1px 0 #79b356, inset 0 0 0 1px #a4cc8b, 0 2px 4px rgba(0, 0, 0, 0.2);
    color: #000;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);


    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    font-family: Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 9px 16px 9px;
    margin: auto;
    transition: all 20ms ease-out;
    vertical-align: top;


    display: block;
    text-align: center;
    width: 200px;
    margin-top: 50px;
   

 

    &:hover {
        color: #fff;
    }

}
   
.language {
    color: #fbfbfb;
    display: inline;
    padding: 8px 0 0 8px;
    float:right;
    width: 20px;
    a {
        font-size: 19px;
        color: rgb(255, 255, 255);
    }
}

.languageMobile {
    color: #fbfbfb;

    padding: 8px 0 0 8px;
    float:right;
    width: 20px;
    a {
        font-size: 19px;
        color: rgb(255, 255, 255);
    }
}

p {
    
    img {
        width: 100%;
        margin: 20px 0 20px 0;
    }
}
